import { Injectable, signal } from '@angular/core';
import { StateStoreService } from 'src/services/state-store/state-store.service';
import { Colleague, IPicture, OfficeStructure, Structure } from '@appines/appines_types';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, from, map, Observable, Subject, switchMap, tap, throwError } from 'rxjs';
import { ModalController } from '@ionic/angular/standalone';
import { CustomUploadComponent } from 'src/components/custom-upload/custom-upload.component';
import { CraftedStructure } from './structure-form.service';

export type StructureState = Structure & { structureLoaded: boolean };
@Injectable({
  providedIn: 'root'
})
export class StructureService extends StateStoreService<StructureState> {
  refreshSubject = new Subject<void>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private modalController: ModalController
  ) {
    super();
  }

  setStructureInState(id: string) {
    if (!this.state()?._id || this.state()?._id !== id) {
      this.getStructure(id).subscribe({
        error: (e) => {
          console.log('error: ', e);
          this.router.navigate(['/profile']).then();
        }
      });
    }
  }

  getStructures(withoutMissingFields?: boolean) {
    const url = new URL('/structures', environment.apiUrl);
    if (withoutMissingFields) url.searchParams.set('withoutMissingFields', 'true');
    return this.http.get<{ data: Structure[] }>(url.toString());
  }

  getStructure(id: string) {
    const { href } = new URL(`/structures/${id}`, environment.apiUrl);
    this.set('structureLoaded', false);

    return this.http.get<Structure>(href).pipe(
      map((data) => {
        this.setState({ ...data, structureLoaded: true });
        return data;
      })
    );
  }

  mapCallback(data: Object) {
    this.setState({ ...this.state(), ...data });
    return data;
  }

  updateStructureInformation(structureId: string, fieldsToUpdate: Pick<Structure, 'name' | 'type'> & Pick<OfficeStructure, 'officeType'>) {
    const { href } = new URL(`/structures/${structureId}`, environment.apiUrl);
    return this.http.put(href, fieldsToUpdate).pipe(map((data) => this.mapCallback(data)));
  }

  updateDescription({ structureId, fieldsToUpdate: { description } }: { structureId: string; fieldsToUpdate: Pick<Structure, 'description'> }) {
    const { href } = new URL(`/structures/${structureId}/description`, environment.apiUrl);
    return this.http.put(href, { description }).pipe(map((data) => this.mapCallback(data)));
  }

  updatePictures({
    structureId,
    fieldsToUpdate: { picturesToAddOrMove, picturesToDelete }
  }: {
    structureId: string;
    fieldsToUpdate: { picturesToAddOrMove: ({ data: IPicture } | { _id: string })[]; picturesToDelete: { _id: string }[] };
  }): Observable<Structure> {
    const { href } = new URL(`/structures/${structureId}/update-pictures`, environment.apiUrl);
    return this.http.put<Structure>(href, { picturesToAddOrMove, picturesToDelete }).pipe(map((data) => this.mapCallback(data) as Structure));
  }

  addColleague(structureId: string, body: Colleague[]) {
    const { href } = new URL(`/structures/${structureId}/colleagues`, environment.apiUrl);
    return this.http.put(href, body).pipe(map((data) => this.mapCallback(data)));
  }

  uploadTemporaryPicture(data: string, pictureNumber: number): Observable<IPicture> {
    const url = environment.apiUrl + `/structures/upload-picture`;

    const response = new Subject<any>();
    const progress = signal<number>(0);

    const upload$ = from(
      this.modalController.create({
        component: CustomUploadComponent,
        componentProps: { progress, pictureNumber },
        cssClass: 'dynamic-height-modal',
        backdropDismiss: false
      })
    ).pipe(
      switchMap((loadingModal) => {
        loadingModal.present();
        return this.http.post<IPicture>(url, { data }, { reportProgress: true, observe: 'events' }).pipe(
          tap((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                if (event.total) {
                  progress.set(Math.round((100 * event.loaded) / event.total));
                }
                break;
              case HttpEventType.Response:
                loadingModal.dismiss();
                console.log(event, event.body);
                response.next(event.body);
                break;
            }
          }),
          catchError((error) => {
            loadingModal.dismiss();
            console.error('An error appear during upload', error);
            return throwError(() => error);
          })
        );
      })
    );
    return upload$.pipe(
      switchMap(() =>
        response.pipe(
          map((res) => {
            return res;
          })
        )
      )
    );
  }

  deleteTemporaryPicture(picture: IPicture) {
    const { href } = new URL(`/structures/delete-uploaded-picture`, environment.apiUrl);
    return this.http.post(href, picture);
  }

  deleteStructure(structureId: string) {
    const { href } = new URL(`/structures/${structureId}`, environment.apiUrl);
    return this.http.delete(href).pipe(
      map((updatedStructure) => {
        this.refreshSubject.next();
        return updatedStructure;
      })
    );
  }

  createStructure(structure: CraftedStructure) {
    const url = environment.apiUrl + '/structures';
    return this.http.post(url, structure).pipe(
      map((newStructure) => {
        this.refreshSubject.next();
        return newStructure;
      })
    );
  }

  provideMissingInformation(structureId: string, body: { siret: string; name: string; address: string }) {
    const url = environment.apiUrl + `/structures/${structureId}/missingFields`;
    return this.http.put<Structure>(url, body).pipe(
      map((updatedStructure) => {
        this.refreshSubject.next();
        this.resetState();
        return updatedStructure;
      })
    );
  }
}
