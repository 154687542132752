<ion-app>
  <ion-split-pane contentId="main-content" when="(min-width: 1280px)" [disabled]="disabledMenu()" style="--side-max-width: 300px">
    <ion-menu contentId="main-content" type="overlay" menuId="sidebar" swipeGesture="false">
      @if (!disabledMenu()) {
        <div class="flex h-full flex-col px-5 py-10 max-desktop:hidden overflow-y-scroll no-scrollbar">
          <!-- LOGO -->
          <div class="w-full hover:cursor-pointer" routerLink="/home">
            <img ngSrc="assets/images/appines/logo-appines-title.png" alt="appines-logo" height="1656" width="5355" priority class="border-box mx-auto h-[54px] w-auto p-2" />
          </div>

          <!-- PROFILE -->
          <div class="my-8 rounded-200 border border-brand-50 p-2">
            <div class="flex items-center rounded-200 p-2 hover:cursor-pointer hover:bg-neutral-200" (click)="isExpanded.set(!isExpanded())">
              <arc-avatar size="small" [user]="user()"></arc-avatar>
              <div class="body-regular-2 mx-3 flex items-center text-brand-700">
                {{ user()?.firstName }} {{ user().lastName }}
                @if (badges().total > 0 && !isExpanded()) {
                  <arc-badge class="ml-2" size="medium" variant="tertiary" showCount [count]="badges().total"></arc-badge>
                }
              </div>
              <arc-icon [name]="isExpanded() ? 'ChevronUp' : 'ChevronDown'" class="ml-auto"></arc-icon>
            </div>

            <!-- PART TO HIDE / SHOW-->
            <div [@expandCollapse]="isExpanded() ? 'expanded' : 'collapsed'" class="overflow-hidden px-2">
              <div class="body-regular-3 mt-5 space-y-4">
                @for (item of profileItems; track item.label; let last = $last) {
                  @if (last) {
                    <hr class="border border-b-0 border-l-0 border-r-0 border-t-neutral-300" />
                  }
                  <arc-menu-button-child (click)="item.action()" [ngClass]="{ 'pb-2': last }" [label]="item.label" [badge]="item.badge?.()" [badgeVariant]="item.badgeVariant!" [active]="item.matchingUrl()"></arc-menu-button-child>
                }
              </div>
            </div>
          </div>

          <div class="space-y-3">
            @for (item of menuItemsTop; track item.label) {
              <div>
                <arc-menu-button
                  [iconName]="item.iconName"
                  [label]="item.label"
                  (click)="item.action()"
                  state="expanded"
                  [selected]="item.matchingUrl()"
                  [badge]="item.badge ? item.badge() : undefined"
                  [badgeVariant]="item.badgeVariant!"
                ></arc-menu-button>
              </div>
            }
          </div>

          <hr class="my-6 border border-b-0 border-l-0 border-r-0 border-t-neutral-300" />

          <div class="space-y-3">
            @for (item of menuItemsBottom; track item.label) {
              <div>
                <arc-menu-button
                  [iconName]="item.iconName"
                  [label]="item.label"
                  (click)="item.action()"
                  state="expanded"
                  [selected]="item.matchingUrl()"
                  [badge]="item.badge ? item.badge() : undefined"
                  [badgeVariant]="item.badgeVariant!"
                  [hasChild]="item?.children?.length"
                  [isChildExpanded]="item?.isExpanded?.() || false"
                  (isChildExpandedChange)="item?.isExpanded?.set($event)"
                ></arc-menu-button>

                <!-- PART TO HIDE / SHOW-->
                <div [@expandCollapse]="item?.isExpanded?.() ? 'expanded' : 'collapsed'" class="overflow-hidden px-3">
                  <div class="body-regular-3 mt-5 space-y-4">
                    @for (child of item?.children; track child.label) {
                      <arc-menu-button-child (click)="child.action()" [label]="child.label" [badge]="child.badge?.()" [badgeVariant]="child.badgeVariant!" [active]="child.matchingUrl()"></arc-menu-button-child>
                    }
                  </div>
                </div>
              </div>
            }
          </div>

          <arc-menu-button iconName="LogOut" label="Me déconnecter" state="expanded" (click)="logout()" class="mt-auto"></arc-menu-button>
        </div>
      }
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
