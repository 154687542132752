import { Routes } from '@angular/router';
import { AuthGuard } from '../../guards/auth/auth.guard';
import { CreateMissionHaveStructureGuard } from 'src/app/guards/create-mission-have-structure/create-mission-have-structure.guard';

export const missionsRoutes: Routes = [
  {
    path: 'missions',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create-mission',
        loadComponent: () => import('./create-mission/create-mission.page').then((m) => m.CreateMissionPage),
        canActivate: [CreateMissionHaveStructureGuard]
      }
    ]
  },
  {
    path: 'mission',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':missionId/boost',
        loadComponent: () => import('src/app/pages/missions/boost-mission/boost-mission.page').then((m) => m.BoostMissionPage)
      },
      {
        path: ':missionId/edit',
        loadComponent: () => import('./edit-mission/edit-mission.page').then((m) => m.EditMissionPage)
      }
    ]
  }
];
