import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { IonApp, IonMenu, IonRouterOutlet, IonSplitPane } from '@ionic/angular/standalone';
import { Platform } from '@ionic/angular';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import { FacebookLoginService } from '../services/facebook-login/facebook-login.service';
import { GoogleAuthService } from '../services/google-auth/google-auth.service';
import { Event, NavigationStart, ResolveEnd, Router, RouterLink } from '@angular/router';
import { ArcAvatar, ArcBadge, ArcIcon, ArcMenuButton, IconNames, MenuButtonChildComponent } from '@appines/archimed';
import { AuthenticationService } from './pages/authentication/authentication.service';
import { OneSignalService } from '../services/one-signal/one-signal.service';
import { BranchIoService } from '../services/branch-io/branch-io.service';
import { GoogleMapsService } from '../services/google-maps/google-maps.service';
import { FaviconService } from '../services/favicon/favicon.service';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { Badge } from 'src/services/badge/badge.service';
import { BadgeService } from '../services/badge/badge.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { User } from '@appines/appines_types';
import { animate, state, style, transition, trigger } from '@angular/animations';

type MenuItem = {
  label: string;
  iconName: IconNames;
  action: () => void;
  matchingUrl: Signal<boolean>;
  badge?: Signal<number> | undefined;
  badgeVariant?: 'primary' | 'secondary' | 'tertiary' | 'red';
  children?: Omit<MenuItem, 'iconName' | 'children'>[];
  isExpanded?: WritableSignal<boolean>;
};

Sentry.init(
  {
    dsn: environment.sentryDSN,
    environment: environment.environmentName,
    enabled: environment.environmentName !== 'development',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration({}),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
  standalone: true,
  imports: [IonApp, IonSplitPane, IonMenu, IonRouterOutlet, NgOptimizedImage, RouterLink, ArcAvatar, ArcIcon, ArcMenuButton, NgClass, ArcBadge, MenuButtonChildComponent],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          opacity: 0,
          overflow: 'hidden'
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          opacity: 1
        })
      ),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')])
    ])
  ]
})
export class AppComponent {
  disabledMenu: WritableSignal<boolean> = signal(false);
  protected isExpanded: WritableSignal<boolean> = signal(false);
  protected currentRootUrl: WritableSignal<string> = signal('');
  private disabledMenuUrls = ['/login', '/register', '/get-started', '/reset-password', '/forget-password'];
  readonly badges: Signal<Badge> = this.badgeService.state.asReadonly();
  protected user: Signal<User> = inject(ProfileService).state.asReadonly();

  profileItems: Omit<MenuItem, 'iconName'>[] = [
    {
      label: 'Profils praticiens',
      action: () => {
        this.router.navigate(['/practitioners']);
      },
      matchingUrl: computed(() => {
        return ['practitioner', 'practitioners'].includes(this.currentRootUrl());
      })
    },
    {
      label: 'Établissements',
      action: () => {
        this.router.navigate(['/structures']);
      },
      matchingUrl: computed(() => {
        return ['structures'].includes(this.currentRootUrl());
      }),
      badge: computed(() => {
        return this.badges ? this.badges!().structure : 0;
      }),
      badgeVariant: 'tertiary'
    },
    {
      label: 'Paramètres',
      action: () => {
        this.router.navigate(['/profile']);
      },
      matchingUrl: computed(() => {
        return ['profile'].includes(this.currentRootUrl());
      })
    }
  ];

  menuItemsTop: MenuItem[] = [
    {
      label: 'Accueil',
      iconName: 'Home',
      action: () => {
        this.router.navigate(['/home']);
      },
      matchingUrl: computed(() => {
        return ['home'].includes(this.currentRootUrl());
      })
    },
    {
      label: 'Notifications',
      iconName: 'BellRing',
      action: () => {
        this.router.navigate(['/notifications']);
      },
      matchingUrl: computed(() => {
        return ['notifications'].includes(this.currentRootUrl());
      })
    },
    {
      label: 'Messages',
      iconName: 'MessagesSquare',
      action: () => {
        this.router.navigate(['/discussions']);
      },
      matchingUrl: computed(() => {
        return ['discussions'].includes(this.currentRootUrl());
      })
    }
  ];

  menuItemsBottom: MenuItem[] = [
    {
      label: 'Emploi',
      iconName: 'Briefcase',
      action: () => {
        this.router.navigate(['/job']);
      },
      matchingUrl: computed(() => {
        return ['job', 'missions'].some((str) => this.currentRootUrl().includes(str));
      }),
      children: [
        {
          label: 'Créer une annonce',
          matchingUrl: computed(() => {
            return ['missions/create-mission'].includes(this.currentRootUrl());
          }),
          action: () => {
            this.router.navigate(['/missions/create-mission']);
          }
        },
        {
          label: 'Gérer mes annonces',
          matchingUrl: computed(() => {
            return ['missions'].includes(this.currentRootUrl());
          }),
          action: () => {
            this.router.navigate(['/missions']);
          }
        },
        {
          label: 'Chercher une annonce',
          matchingUrl: computed(() => {
            return ['missions/search'].includes(this.currentRootUrl());
          }),
          action: () => {}
        },
        {
          label: 'Gérer les favoris',
          matchingUrl: computed(() => {
            return ['missions/search'].includes(this.currentRootUrl());
          }),
          action: () => {}
        },
        {
          label: "Suivi d'emploi",
          matchingUrl: computed(() => {
            return ['missions/search'].includes(this.currentRootUrl());
          }),
          action: () => {}
        },
        {
          label: 'Recherches enregistrées',
          matchingUrl: computed(() => {
            return ['missions/search'].includes(this.currentRootUrl());
          }),
          action: () => {}
        },
        {
          label: 'Gérer les dispo',
          matchingUrl: computed(() => {
            return ['missions/search'].includes(this.currentRootUrl());
          }),
          action: () => {}
        }
      ],
      isExpanded: signal(false)
    },
    {
      label: 'Formation',
      iconName: 'GraduationCap',
      action: () => {
        this.router.navigate(['/formations']);
      },
      matchingUrl: computed(() => {
        return ['formations'].includes(this.currentRootUrl());
      })
    },
    {
      label: 'Réseau',
      iconName: 'Users',
      action: () => {
        this.router.navigate(['/community']);
      },
      matchingUrl: computed(() => {
        return ['community'].includes(this.currentRootUrl());
      })
    }
  ];

  constructor(
    private router: Router,
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private facebookLoginService: FacebookLoginService,
    private branchIoService: BranchIoService,
    private googleMapsLoader: GoogleMapsService,
    private oneSignalService: OneSignalService,
    private googleAuthService: GoogleAuthService,
    private faviconService: FaviconService,
    private badgeService: BadgeService
  ) {
    this.subscribeRouterEvents();
    this.initializeApp();
    this.oneSignalService.initOneSignal();
  }

  private initializeApp(): void {
    this.platform.ready().then(async () => {
      // Branch (deep links)
      this.branchIoService.initDeepLinks();

      // Facebook Login
      this.facebookLoginService.initialize();

      // Google Auth
      this.googleAuthService.initialize();

      // Load Google Maps Script
      try {
        await this.googleMapsLoader.loadLibraries(['places']);
        console.info('Google Maps loaded successfully');
      } catch (error) {
        console.error('Unable to load Google Maps');
        console.error(error);
      }

      // Change favicon
      this.faviconService.initFaviconService();
    });
  }

  private subscribeRouterEvents() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.disabledMenu.set(this.disabledMenuUrls.includes(event.url.split('?')[0]));
      }

      if (event instanceof ResolveEnd) {
        this.currentRootUrl.set(event.urlAfterRedirects.split('/').slice(1).join('/'));
      }
    });
  }

  protected logout() {
    this.authenticationService.logout().subscribe();
  }
}
